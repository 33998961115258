@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inika&display=swap');

html {
    max-width: 100vw;
}

.home {
    max-width: 100vw;
    box-sizing: border-box;
}

.txt-dir {
    text-align: right;
}

.txt-esq {
    text-align: left;
}

.txt-just {
    text-align: justify;
}

.sec-1 {
    background-image: url('../../Images/fundo1.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
    color: #FFF;
    font-family: 'Kanit', sans-serif;
    padding: 15vh 0px 0px 0px;
    box-sizing: border-box;
    margin: 0;
    max-width: 100vw;
}

.div-1-sec-1 {
    margin: 0 0 0 20%;
    max-width: 570px;
}

.subtitulo {
    font-size: 36px;
    text-align: right;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    letter-spacing: -1.8px;
    line-height: 90%;
}

.div-2-sec-1 {
    margin: 110px 0px 0px 15%;
    max-width: 25%;
}

.div-2-sec-1 .texto {
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -1.8px;
    margin: 0 0 20px 0;
    line-height: 90%;
}

.sec-2 {
    padding: 10px;
    display: flex;
    max-width: 100vw;
    box-sizing: border-box;
}

.vetor {
    width: 30vw;
    height: 17vw;
    z-index: 2;
    position: relative;
    bottom: 5vw;
    right: 5vw;
    transition: transform 0.3s ease;
}

.vetor:hover {
    transform: translate(-2vw, -3vw);
}

.vetor2 {
    width: 30vw;
    height: 17vw;
    z-index: 1;
    position: absolute;
    margin-top: 5vw;
    transition: transform 0.3s ease;
}

.div-1-sec-2 {
    margin: 5vh 10% 10vh;
    max-width: 45vw;
    padding: 1em 4em;
    box-sizing: border-box;
}

.title-sec {
    color: rgba(51, 51, 51, 0.85);
    text-align: center;
    font-family: 'Kanit';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    letter-spacing: -1.8px;
}

.texto-sec {
    color: rgba(51, 51, 51, 0.85);
    font-family: 'Kanit';
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    letter-spacing: -1.5px;
}

.div-2-sec-2 {
    margin: 0;
    max-width: 55vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sec-3 {
    height: 700px;
    display: flex;
    max-width: 100vw;
}

.elipse {
    z-index: 1;
    position: absolute;
    width: 25vw;
    height: 25vw;
    margin-left: -4vw;
}

.img-computer {
    z-index: 2;
    width: 25vw;
    height: 24vw;
    margin: 90px 10vw 90px;
}

.imgs-computer1 {
    position: relative;
    left: 10vw;
}

.imgs-computer2 {
    position: relative;
    right: 10vw;
}

.grafico {
    position: relative;
    right: 1vw;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    width: 6vw;
    height: 6vw;
}

.seta1 {
    rotate: 20deg;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    width: 6vw;
    height: 6vw;
}

.carregamento {
    position: relative;
    margin-top: 40px;
    right: 2vw;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    width: 6vw;
    height: 6vw;
    z-index: 9;
}

.seta2 {
    rotate: -20deg;
    position: relative;
    left: 3vw;
    bottom: 2vw;
    z-index: 9999;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    width: 6vw;
    height: 6vw;
}

.financeiro {
    position: relative;
    right: 3vw;
    z-index: 9999;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    width: 6vw;
    height: 6vw;
}

.seta3 {
    rotate: 20deg;
    position: relative;
    right: 5vw;
    z-index: 9999;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    width: 6vw;
    height: 6vw;
}

.localizacao {
    position: relative;
    left: 2vw;
    top: 2vw;
    z-index: 9999;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    width: 6vw;
    height: 6vw;
}

.seta4 {
    rotate: 60deg;
    position: relative;
    right: 5vw;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    width: 6vw;
    height: 6vw;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.div-1-sec-3 {
    background: radial-gradient(50% 50% at 50% 50%, rgba(9, 70, 184, 0.70) 0%, rgba(9, 70, 184, 0.85) 48.96%, #0946B8 100%);
    max-width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-2-sec-3 {
    padding: 1em 10%;
    font-size: 30px;
    box-sizing: border-box;
}

.div-2-sec-3 p {
    margin: 3em 0 0 0;
}

.sec-4 {
    display: flex;
    height: 900px;
    max-width: 100vw;
}

.img-caminhao {
    z-index: 9999;
    position: absolute;
    right: 0;
    width: 40vw;
    height: 40vw;
    transition: ease 0.3s;
}

.img-caminhao:hover {
    width: 45vw;
    height: 45vw;
    transition: ease 0.3s;
}

.fundo-caminhao {
    width: 30vw;
    height: 30vw;
    background: radial-gradient(50% 50% at 50% 50%, rgba(9, 70, 184, 0.70) 0%, rgba(9, 70, 184, 0.85) 48.96%, #0946B8 100%);
    z-index: 1;
    position: absolute;
    right: 0;
    margin: 2vw 0 0 0;
}

.div-1-sec-4 {
    padding: 20px 7% 4em 15%;
    max-width: 57.25%;
    box-sizing: border-box;
}

.div-1-sec-4 p {
    margin: 2.5em 0 0 0;
}

.div-2-sec-4 {
    max-width: 40%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-1-sec-4 p {
    font-size: 35px;
}

.sec-5 {
    height: 800px;
    display: flex;
    background: radial-gradient(50% 50% at 50% 50%, rgba(9, 70, 184, 0.80) 0%, rgba(9, 70, 184, 0.90) 48.96%, #0946B8 100%);
    color: #FFF;
    padding: 1em 10% 2em;
    max-width: 100vw;
    box-sizing: border-box;
}

.title-5 {
    color: var(--Basics-white, #FFF);
    text-shadow: -4px 2px 5px rgba(9, 70, 184, 0.75);
    font-family: 'Inika', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 20px 0 40px;
}

.texto-5 {
    color: var(--Basics-white, #FFF);
    text-shadow: 4px 4px 4px rgba(9, 70, 184, 0.20);
    font-family: 'Kanit', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 20px 0;
    max-width: 40ch;
    flex-wrap: nowrap;
}

.img-5 {
    margin-right: 10px;
    width: 90px;
    height: 90px;
}

.div-1-sec-5 {
    width: 50%;
}

.div-2-sec-5 {
    margin: 100px 0 0 0;
    max-width: 50%;
}

.sec-6 {
    display: flex;
    height: 1030px;
}

.div-1-sec-6 {
    max-width: 30%;
    text-align: right;
}

.logo-3 {
    margin: 0 auto;
}

.div-2-sec-6 {
    max-width: 70%;
    box-sizing: border-box;
}

.form {
    margin: 15vh 10vw 0;
    max-width: 70%;
    padding: 10px 100px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FBFBFB;
    box-shadow: 4px 4px 15px 5px rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    max-height: 100vh;
}

.form-title {
    color: rgba(51, 51, 51, 0.85);
    text-align: center;
    font-family: Inika;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form p {
    color: rgba(51, 51, 51, 0.85);
    text-align: center;
    text-shadow: 4px 4px 4px rgba(9, 70, 184, 0.20);
    font-family: 'Krub';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 2em 0;
}

.input-sec {
    background: var(--Basics-white, #FFF);
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.40);
    border: none;
    padding: 1em;
    margin: 0 0 2em 0;
    box-sizing: border-box;
    color: #636363;
}

.input-sec::placeholder {
    color: #949292;
    font-family: 'Krub';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.input-sec:focus {
    outline: none;
}

.btn-form {
    background-color: #0946B8;
    color: #FFF;
    font-family: 'Kanit', sans-serif;
    padding: 0.5em 0;
    width: 100%;
    border: none;
    font-size: 24px;
    letter-spacing: -1.2px;
    border-radius: 15px;
    font-weight: 400;
    transition: 0.5s;
    box-shadow: #949292 2px 2px 4px 1px;
    margin: 1em 0 0 0;
    box-sizing: border-box;
}

.btn-form:hover {
    background-color: #FFF;
    color: #0946B8;
    transition: 0.5s;
    box-shadow: #949292 2px 2px 4px 1px;
}


::-webkit-scrollbar {
    width: 8px;
    background: #0946b800;
}

::-webkit-scrollbar-track {
    border-radius: 10px;

}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #636363;
}

::-webkit-scrollbar-thumb:hover {
    background: #4b4b4b93;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: #FFF;
}

.img-fechar {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {

    .sec-1,
    .sec-2,
    .sec-3,
    .sec-4,
    .sec-5,
    .sec-6 {
        display: block;
    }

    html {
        max-width: 100vw;
    }

    .div-1-sec-1 {
        padding: 0 20px;
    }

    .logo-txt {
        width: 80%;
    }

    .subtitulo {
        margin: 30px 10px 0 0;
        font-size: 22px;
    }

    .title-sec {
        width: 70vw;
        text-align: center;
        font-size: 30px;
    }

    .texto-sec,
    .div-1-sec-4 .texto-sec {
        font-size: 25px;
    }

    .title-5 {
        font-size: 35px;
    }

    .texto-5 {
        font-size: 25px;
    }

    .sec-2 {
        height: 100%;
    }

    .div-2-sec-1 {
        max-width: 50vw;
        margin: 200px 0 0 100px;
    }

    .div-2-sec-1 .texto {
        font-size: 26px;
    }

    .div-1-sec-2 {
        padding: 0;
        margin: 0 auto 100px;
        max-width: 80vw;
    }

    .div-2-sec-2 {
        max-width: 100vw;
    }

    .vetor {
        width: 70vw;
        height: 40vw;
        margin: 0 auto 150px auto;
    }

    .vetor2 {
        width: 70vw;
        height: 40vw;
    }

    .img-computer {
        width: 55vw;
        height: 50vw;
        margin: 12vw auto;
    }

    .elipse {
        width: 50vw;
        height: 50vw;
        margin: 0 auto;
    }

    .seta1,
    .seta2,
    .seta3,
    .seta4,
    .carregamento,
    .financeiro,
    .grafico,
    .localizacao {
        width: 8vw;
        height: 8vw;
    }

    .seta1 {
        position: relative;
        top: 8vw;
        right: 6vw;
    }

    .carregamento {
        position: relative;
        top: 6vw;
        left: 4vw;
    }

    .seta2 {
        position: relative;
        top: 12vw;
    }

    .financeiro {
        position: relative;
        bottom: 8vw;
        left: 9vw;
    }
    .seta3{
        position: relative;
        left: 2vw;
    }
    .localizacao{
        position: relative;
        top: 9vw;
        left: 12vw;
    }
    .seta4{
        position: relative;
        top: 17vw;
        right: 1vw;
    }

    .sec-3 {
        height: 100%;
    }

    .div-1-sec-3 {
        max-width: 100vw;
        height: 100%;
    }

    .div-2-sec-3 {
        width: 100vw;
    }

    .sec-4 {
        height: 100%;
    }

    .div-1-sec-4 {
        max-width: 100vw;
    }

    .div-2-sec-4 {
        max-width: 100vw;
    }

    .sec-5 {
        max-width: 100vw;
        height: 100%;
        padding: 20px 0;
        text-align: center;
    }

    .div-1-sec-5 {
        width: 100vw;
        margin: 0;
        padding: 0 7vw;
        box-sizing: border-box;
    }

    .div-2-sec-5 {
        max-width: 100vw;
        margin: 0 0 50px;
        padding: 0 7vw;
        box-sizing: border-box;
    }

    .texto-5 {
        margin: 50px 0;
    }

    .sec-6 {
        height: 100%;
    }

    .div-1-sec-6 {
        max-width: 100vw;
        display: none;
    }

    .div-2-sec-6 {
        max-width: 100vw;
        margin: 0;
        padding: 0;
    }

    .logo-3 {
        max-width: 100vw;
        z-index: 1;
    }

    .form {
        z-index: 2;
        max-width: 90vw;
        padding: 10px 50px 50px;
        margin: 20px auto 20px;
    }

    .form-title {
        font-size: 40px;
        margin-bottom: .5em;
    }

    .form p {
        font-size: 30px;
        margin-bottom: .5em;
    }

    .input-sec {
        font-size: 22px;
        padding: 0.5em;
        margin-bottom: .5em;
    }

    .input-sec::placeholder {
        font-size: 22px;
    }

    .img-caminhao {
        width: 90vw;
        height: 90vw;
        margin: 0 0 10vw 0;
    }

    .img-caminhao:hover {
        width: 99vw;
        height: 99vw;
    }

    .fundo-caminhao {
        width: 100vw;
        height: 90vw;
        margin: 10vw 0 10vh 0;
        position: relative;
    }
}

@media screen and (min-width:770px) and (max-width: 1900px) {
    .sec-2 {
        height: 100%;
    }

    .sec-3 {
        height: 100%;
    }

    .sec-4 {
        height: 100%;
    }

    .sec-5 {
        height: 100%;
    }

    .sec-6 {
        height: 100%;
    }

    .div-2-sec-2 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .vetor {
        max-width: 33vw;
        margin: 0 2vw 0 0;
    }

    .div-1-sec-3 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .div-2-sec-4 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}