@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;400&display=swap');

.btn{
    font-family: 'Kanit', sans-serif;
    padding: 0.5em 0;
    width: 100%;
    color: #0946B8;
    border: none;
    font-size: 24px;
    letter-spacing: -1.2px;
    border-radius: 15px;
    font-weight: 400;
    transition: 0.5s;
}

.btn:hover{
    background-color: #0946B8;
    color: #FFF;
    transition: 0.5s;
}