.footer{
    max-width: 100vw;
    height: 60px;
    color: #FFF;
    background: #0946B8;
    padding: 15px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
}
.div-footer{
    margin: 0 auto;
    text-align: center;
}
.title-footer{
    text-align: center;
    margin: 0;
    font-weight: 500;
    position: absolute;
}
.contatos{
    margin-right: 20px;
}
.ctt{
    margin: 0px 10px 0px 0px;
}
@media screen and (max-width: 768px){
    .title-footer{
        position: relative;
    }
}